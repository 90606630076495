<template>
    <v-fade-transition>
        <div class="col-12 col-xs-12 col-lg-5">
            <v-sheet class="dense-inputs">
                <v-row no-gutters>
                    <v-col cols="12" lg="12" md="12" xs="12" class="d-flex flex-row float-right">
                        <v-text-field
                            :label="$t('message.filterResults')"
                            :value="searchTerm"
                            autocomplete="off"
                            class="mt-2 mb-0 force-text-left"
                            prepend-inner-icon="mdi-filter-outline"
                            @change="searchTerm = $event"
                        ></v-text-field>
                        <v-btn class="ml-3" :loading="loading.filterResults" @click="filterResults">{{ $t('message.filter') }}</v-btn>
                        <v-btn class="ml-3" @click="searchTerm = null"><v-icon>refresh</v-icon></v-btn>
                        <ExportTable
                            :has-add-function="true"
                            :cols-to-delete="colsToDelete"
                            class="mt-0 mr-0"
                            file-name="audits"
                            table-id="auditsTable"
                            style="padding-top: 2px !important;"
                            @add-action="openCreateAuditDialog()"
                        ></ExportTable>
                    </v-col>
                </v-row>
            </v-sheet>
            <v-overlay
                :value="loading.audits"
                absolute
                opacity="0.15"
            >
                <v-row>
                    <v-col class="text-center">
                        <v-progress-circular
                            color="primary"
                            indeterminate
                            size="40"
                            width="6"
                        ></v-progress-circular>
                    </v-col>
                </v-row>
            </v-overlay>
            <v-data-table
                :items="audits"
                :items-per-page="-1"
                :headers="headers"
                :height="tableHeight"
                class="mt-0 appic-table-light specification-table"
                dense
                disable-pagination
                fixed-header
                hide-default-footer
                id="auditsTable"
            >
                <template v-slot:item.Audit.id="{item}">
                    <v-menu>
                        <template v-slot:activator="{ on: menu }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                        <v-icon>more_vert</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('message.moreActions') }}</span>
                            </v-tooltip>
                        </template>
                        <v-list dense>
                            <v-list-item class="font-sm" @click="openEditAuditDialog(item.Audit.id, item.Audit.title)" v-if="$can('update','FscPefcAudit')">
                                <v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateAudit') }}
                            </v-list-item>
                            <v-list-item class="font-sm" @click="deleteAudit(item.Audit.id, item.Audit.title)" v-if="$can('delete','FscPefcAudit')">
                                <v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>{{ $t('message.deleteAudit') }}
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
                <template v-slot:item.Audit.title="{item}">
                    <div class="text-no-wrap font-weight-bold">{{ item.Audit.title }}</div>
                </template>
                <template v-slot:item.Audit.date="{item}">
                    <div class="text-no-wrap">{{ formatDate(item.Audit.date) }}</div>
                </template>
                <template v-slot:item.Audit.start_date="{item}">
                    <div class="text-no-wrap">{{ formatDate(item.Audit.start_date) }}</div>
                </template>
                <template v-slot:item.Audit.end_date="{item}">
                    <div class="text-no-wrap">{{ formatDate(item.Audit.end_date) }}</div>
                </template>
                <template v-slot:item.Audit.type="{item}">
                    <div class="text-no-wrap">{{ item.Audit.type ? item.Audit.type.toUpperCase() : '' }}</div>
                </template>
            </v-data-table>
            <AddAudit
                :audit-id="selectedAuditId"
                :audit-name="selectedAuditName"
                :dialog.sync="editDialog"
                :update-mode="updateMode"
                @dialog-closed="editDialogClosed"
                @update-done="auditUpdated"
                @create-done="auditCreated"
            ></AddAudit>
        </div>
    </v-fade-transition>
</template>

<script>
import {api} from "Api";
import { formatDate, log, numberFormat } from "Helpers/helpers";

const AddAudit = () => import("Components/Appic/FscPefc/AddAudit");
const ExportTable = () => import("Components/Appic/ExportTable");

export default {
    name: "AuditList",
    components: {AddAudit, ExportTable},
    data() {
        return {
            audits: [],
            colsToDelete: {
                start_col: 0,
                ncols: 1
            },
            dialogs: {
                error: false,
                error_message: ""
            },
            editDialog: null,
            loading: {
                clear: false,
                audits: false,
                filterResults: false
            },
            searchTerm: null,
            selectedAuditId: null,
            selectedAuditName: null,
            tableHeight: '500',
            updateMode: false
        }
    },
    computed: {
        headers() {
            return [
                {
                    id: 1,
                    text: this.$t('message.actions'),
                    value: 'Audit.id',
                    class: 'light-green lighten-3 pa-1 width-1-pct',
                    sortable: false,
                    searchable: false
                },
                {
                    id: 2,
                    text: this.$t('message.createDate'),
                    value: 'Audit.date',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 3,
                    text: this.$t('message.auditNumber'),
                    value: 'Audit.title',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 4,
                    text: this.$t('message.type'),
                    value: 'Audit.type',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 5,
                    text: this.$t('message.auditYear'),
                    value: 'Audit.audit_year',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 6,
                    text: this.$t('message.startDate'),
                    value: 'Audit.start_date',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 7,
                    text: this.$t('message.endDate'),
                    value: 'Audit.end_date',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
            ]
        }
    },
    methods: {
        auditCreated () {
            this.editDialog = false;
            this.loadAudits()
            this.$emit('audits-updated')
        },
        auditUpdated () {
            this.editDialog = false;
            this.loadAudits()
            this.$emit('audits-updated')
        },
        async deleteAudit(val, title) {
            if(await this.$root.$confirm(this.$t('message.deleteAudit') + ' ' +  title, this.$t('message.confirmations.continueAuditCancelAction'), {color: 'orange'})){

                const promise = new Promise((resolve, reject) => {
                    api
                        .delete("/fsc-pefc/audit/" + val)
                        .then(response => {
                            if (response.data.status == 'success') {
                                resolve('done')
                            } else {
                                resolve('error')
                            }
                        })
                        .catch(error => {
                            reject(error);
                        });
                })

                promise
                    .then((status) => {
                        if(status == 'done'){
                            this.$toast.success(this.$t('message.successes.auditDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loadAudits()
                            this.$emit('audits-updated')
                        } else {
                            this.$toast.error(this.$t('message.errors.auditNotDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
            }
        },
        editDialogClosed () {
            this.editDialog = false;
            this.loadAudits()
        },
        filterResults () {
            this.loading.filterResults = true
            let _this = this
            setTimeout(function(){
                _this.loading.filterResults = false
            },700)
        },
        formatDate,
        handleResize() {
            this.tableHeight = window.innerHeight - (225);
        },
        loadAudits() {
            return new Promise((resolve, reject) => {
                this.loading.audits = true
                api
                    .get('/fsc-pefc/audits')
                    .then((response) => {
                        if(response.data.status == 'success') {
                            this.audits = response.data.data
                            resolve('done')
                        } else {
                            reject(response.data.status)
                        }
                        this.loading.audits = false
                    })
                    .catch((error) => {
                        this.$toast.error( error,
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        reject('error')
                        this.loading.audits = false
                    });
            })
        },
        openCreateAuditDialog () {
            this.updateMode = false
            this.editDialog = true
        },
        openEditAuditDialog(auditId, auditName) {
            this.selectedAuditId = auditId
            this.selectedAuditName = auditName
            this.updateMode = true
            this.editDialog = true
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize)
    },
    mounted() {
        this.handleResize()
        this.loadAudits()
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style scoped>
.v-data-table::v-deep .text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.v-data-table::v-deep td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
.v-data-table::v-deep th {
    white-space: nowrap;
}
.v-data-table::v-deep th.force-text-right {
    text-align: right !important;
}
</style>